:root {
  --ion-color-primary: #424242;
  --ion-color-primary-rgb: 66,66,66;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3a3a3a;
  --ion-color-primary-tint: #555555;

  --ion-color-secondary: #EEEEEE;
  --ion-color-secondary-rgb: 238,238,238;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #d1d1d1;
  --ion-color-secondary-tint: #f0f0f0;

  --ion-color-tertiary: #BDBDBD;
  --ion-color-tertiary-rgb: 189,189,189;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #a6a6a6;
  --ion-color-tertiary-tint: #c4c4c4;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;


  //Couleurs des catégories
  --ion-color-a: #eeaa3c;
  --ion-color-a-rgb: 238,170,60;
  --ion-color-a-contrast: #000000;
  --ion-color-a-contrast-rgb: 0,0,0;
  --ion-color-a-shade: #d19635;
  --ion-color-a-tint: #f0b350;

  --ion-color-b: #86bd4a;
  --ion-color-b-rgb: 134,189,74;
  --ion-color-b-contrast: #000000;
  --ion-color-b-contrast-rgb: 0,0,0;
  --ion-color-b-shade: #76a641;
  --ion-color-b-tint: #92c45c;

  --ion-color-c: #e26e3e;
  --ion-color-c-rgb: 226,110,62;
  --ion-color-c-contrast: #000000;
  --ion-color-c-contrast-rgb: 0,0,0;
  --ion-color-c-shade: #c76137;
  --ion-color-c-tint: #e57d51;

  --ion-color-d: #58b8da;
  --ion-color-d-rgb: 88,184,218;
  --ion-color-d-contrast: #000000;
  --ion-color-d-contrast-rgb: 0,0,0;
  --ion-color-d-shade: #4da2c0;
  --ion-color-d-tint: #69bfde;

  --ion-color-e: #b891b5;
  --ion-color-e-rgb: 184,145,181;
  --ion-color-e-contrast: #000000;
  --ion-color-e-contrast-rgb: 0,0,0;
  --ion-color-e-shade: #a2809f;
  --ion-color-e-tint: #bf9cbc;

}

.ion-color-a {
  --ion-color-base: var(--ion-color-a);
  --ion-color-base-rgb: var(--ion-color-a-rgb);
  --ion-color-contrast: var(--ion-color-a-contrast);
  --ion-color-contrast-rgb: var(--ion-color-a-contrast-rgb);
  --ion-color-shade: var(--ion-color-a-shade);
  --ion-color-tint: var(--ion-color-a-tint);
}

.ion-color-b {
  --ion-color-base: var(--ion-color-b);
  --ion-color-base-rgb: var(--ion-color-b-rgb);
  --ion-color-contrast: var(--ion-color-b-contrast);
  --ion-color-contrast-rgb: var(--ion-color-b-contrast-rgb);
  --ion-color-shade: var(--ion-color-b-shade);
  --ion-color-tint: var(--ion-color-b-tint);
}

.ion-color-c {
  --ion-color-base: var(--ion-color-c);
  --ion-color-base-rgb: var(--ion-color-c-rgb);
  --ion-color-contrast: var(--ion-color-c-contrast);
  --ion-color-contrast-rgb: var(--ion-color-c-contrast-rgb);
  --ion-color-shade: var(--ion-color-c-shade);
  --ion-color-tint: var(--ion-color-c-tint);
}

.ion-color-d {
  --ion-color-base: var(--ion-color-d);
  --ion-color-base-rgb: var(--ion-color-d-rgb);
  --ion-color-contrast: var(--ion-color-d-contrast);
  --ion-color-contrast-rgb: var(--ion-color-d-contrast-rgb);
  --ion-color-shade: var(--ion-color-d-shade);
  --ion-color-tint: var(--ion-color-d-tint);
}

.ion-color-e {
  --ion-color-base: var(--ion-color-e);
  --ion-color-base-rgb: var(--ion-color-e-rgb);
  --ion-color-contrast: var(--ion-color-e-contrast);
  --ion-color-contrast-rgb: var(--ion-color-e-contrast-rgb);
  --ion-color-shade: var(--ion-color-e-shade);
  --ion-color-tint: var(--ion-color-e-tint);
}

* {
  font-family: 'Barlow Semi Condensed', sans-serif;
}
