/* You can add global styles to this file, and also import other style files */
.app-full-bleed-dialog .mat-dialog-container {
  padding: 5px 0 0 0;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Barlow Semi Condensed, sans-serif;
}

.flex-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}

.container {
  margin: auto;
  padding: 0px;
  width: 60%;
}

.my-custom-class .popover-wrapper {
  --min-height: 60%;
  --min-width: 60%;
  padding-right: 50%;
}

.mat-toolbar {
  background-color: white !important;
  min-height: 40px !important;
  height: 40px !important;
}

.user-chip {
  border-radius: 0px !important;
  min-height: 24px !important;
  height: 24px !important;
}

.header-divider {
  border-top-color: black;
  border-top-width: 1px;
}


.mat-form-field-appearance-outline
  .mat-form-field-outline
  .mat-form-field-outline-start,
.mat-form-field-appearance-outline
  .mat-form-field-outline
  .mat-form-field-outline-end {
  border-radius: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Barlow Semi Condensed', sans-serif;
}

ion-slides {
  --bullet-background: grey;
}
